import React, { useEffect } from "react";
import Footer from "../../components/molecules/Footer/Footer";

function OurClients() {
	const clienti = [
		"Brunello Cucinelli",
		"Harmont & Blaine",
		"Veuve Clicquot",
		"Sammontana",
		"Radio Dj",
		"Twinset",
		"Chanel",
		"Polli",
		"Knt",
		"Kiton",
		"Elmar",
		"Veralab",
		"Coccinelle",
		"Antonia Milano",
		"Emporio Armani",
		"Fondazione Mente",
	];

	useEffect(()=>{
		const clienti = document.getElementsByClassName('clienti');
		setTimeout(()=>{
			for (let i = 0; i < clienti.length; i++) {
				clienti[i].classList.remove('animate__animated');
				
			}
		},2800)
	})
	return (
		<div className="">
			<div className=" text-white h-[100vh] w-full flex items-center justify-center">
				<video
					className="fixed w-full h-full opacity-50 object-cover"
					autoPlay
					muted
					loop
				>
					<source
						src={require("../../assets/SFONDO(1).mov")}
						type="video/mp4"
					/>
				</video>
				<div className="relative">
					{clienti.map((cliente, i) => {
						return (
							<p
								key={i}
								className={` animate__animated animate__slow clienti text-center p-1 ${
									i % 2 === 0
										? "animate__fadeInLeftBig"
										: "animate__fadeInRightBig"
								}`}
							>
								{cliente}
							</p>
						);
					})}
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default OurClients;
