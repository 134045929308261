import React, { useState } from "react";
import { useParams } from "react-router-dom";

function AfterMail() {
	const slug = useParams().slug;
	const [t1, setT1] = useState("");
	const [loaded, setLoaded] = useState(false);
	const name = slug[0].toUpperCase() + slug.slice(1);
	
	if (!loaded) {
		if (slug !== "error") {
			setT1(`La tua richiesta è andata a buon fine!`);
		} else {
			setT1("Qualcosa è andato storto");
		}
		setLoaded(true);
	}

	console.log(t1, slug);

	return (
		<div className="w-full h-[100vh] flex justify-center items-center">
			{loaded ? (
				<div className="text-white font-extralight inline-block">
					<p className="text-4xl">{t1}</p>
					<a className="text-center text-xl" href="/"> Torna alla home page</a>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
}

export default AfterMail;
