import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TeamCard from "../../components/atoms/TeamCard/TeamCard";
import { team } from "./team";
import ScrollContainer from "react-indiana-drag-scroll";
import Footer from "../../components/molecules/Footer/Footer";

function AboutUs() {
	const { t } = useTranslation();

	const fadeOutTitle = () => {};

	useEffect(() => {
		window.addEventListener("scroll", () => {
			const title = document.getElementById("aboutus_title");
			const photo = document.getElementById("aboutus_photo");
			const scr = window.pageYOffset;
			const hh = window.innerHeight;

			const opacity = (1 - ((scr + 1) * 3) / hh) * 100;
			const scale = (1 + ((scr + 1) * 0.5) / hh) * 100;
			if (window.innerWidth > 640) {
				title.style.opacity = opacity + "%";
				photo.style.scale = scale + "%";
			}
		});
	}, []);

	return (
		<div id="chi" className={``}>
			<div className="mt-0 relative md:mt-0  w-full h-[100vh] justify-center  text-white">
				<div
					id="XXXaboutus_photo"
					className="flex flex-col overflow-hidden relative justify-center z-10 "
				>
					<div className="w-full h-[100vh] flex justify-center overflow-hidden bg-transparent ">
						<img
							draggable={false}
							src={require("../../assets/WHO-WE-ARE-BANNER.jpg")}
							alt={"bg"}
							className=" transition-all flex overflow-hidden  duration-500 object-cover brightness-[.4]"
							height={"100vh"}
							width={'100%'}
						/>
					</div>

					<div
						id="aboutus_title"
						className="md:fixed absolute md:left-[50%] md:translate-x-[-50%]"
					>
						<h1 className="text-4xl text-center md:text-6xl translate-y-[-50%] font-semibold uppercase text-shadow">
							{t("who_are_we")}
						</h1>
						<div className=" md:px-20 px-2 py-5 pt-16 pb-10 text-xl font-medium text-shadow text-justify">
							<p className=" leading-8">
								{t("chisiamo_desc")}
							</p>
							<p className="w-full font-bold text-shadow text-center text-3xl">
								<br />
								Let’s start
							</p>
						</div>
					</div>
				</div>
				<div
					id="scrollmenu"
					className=" relative block z-20 bg-[rgb(10,10,10)] md:pl-32 pb-32 "
				>
					<div className="py-12 pl-2">
						<p className="uppercase text-2xl">Chimera studio</p>
						<h2 className="uppercase text-5xl font-semibold pt-5">
							team & collaboratori
						</h2>
					</div>

					<ScrollContainer vertical="false">
						<div className="flex teamcard cursor-ew-resize  ">
							{team.map((person, index) => {
								return (
									<TeamCard
										id={index}
										className={`pb-10`}
										name={person.nome}
										img={person.foto}
										role={person.role}
										grade={person.grade}
									/>
								);
							})}
						</div>
					</ScrollContainer>
				</div>
				<Footer />
			</div>
		</div>
	);
}

export default AboutUs;

/*
<div id="carousel" className={`w-[1000vw] relative pt-10 `}>
					<div className="teamcard text-lg font-thin w-[350px] h-[350px] absolute flex justify-center items-center">
						<div>
							<p>Scorri giù</p>
							<i className=" text-white fa-solid fa-arrow-down"></i>
						</div>
					</div>
					
				</div>
{team.map((person, index) => {
						return (
							<div className="teamcard absolute" key={index}>
								<TeamCard
									id={index}
									className={``}
									name={person.nome}
									img={person.foto}
								/>
							</div>
						);
					})} */
