import React, { useEffect, useState } from "react";
import Home from "../Home/Home";
import "./FirstEntry.css";
import { useTranslation } from "react-i18next";

export default function FirstEntry() {
	//window.scrollTo(0, 0);
	const [fadedown, setFadedown] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		var canvas = document.querySelector("#scene"),
			ctx = canvas.getContext("2d"),
			particles = [],
			amount = 0,
			mouse = { x: 0, y: 0 },
			radius = 2;

		var colors = ["#b2b2b2", "#f2f2f2", "#e8e8e8", "#a9a9a9", "#e1e1e1"];

		var copy = document.querySelector("#copy");

		var ww = (canvas.width = window.innerWidth);
		var wh = (canvas.height = window.innerHeight);

		class Particle {
			constructor(x, y) {
				this.x = Math.random() * ww;
				this.y = Math.random() * wh;
				this.dest = {
					x: x,
					y: y,
				};
				this.r = (Math.random() * ww) / 300 + 2;
				this.vx = (Math.random() - 0.5) * 2;
				this.vy = (Math.random() - 0.5) * 2;
				this.accX = 0;
				this.accY = 0;
				this.friction = Math.random() * 0.04 + 0.9;

				this.color = colors[Math.floor(Math.random() * 6)];
			}
			render() {
				this.accX = (this.dest.x - this.x) / 100;
				this.accY = (this.dest.y - this.y) / 100;
				this.vx += this.accX;
				this.vy += this.accY;
				this.vx *= this.friction;
				this.vy *= this.friction;

				this.x += this.vx;
				this.y += this.vy;

				ctx.fillStyle = this.color;
				ctx.font = "bold " + ww / 8+ "px IBM Plex Mono";

				ctx.beginPath();
				ctx.arc(this.x, this.y, this.r, Math.PI * 2, false);
				ctx.fill();

				var a = this.x - mouse.x;
				var b = this.y - mouse.y;

				var distance = Math.sqrt(a * a + b * b);
				if (distance < radius * 70) {
					this.accX = (this.x - mouse.x) / 100;
					this.accY = (this.y - mouse.y) / 100;
					this.vx += this.accX;
					this.vy += this.accY;
				}
			}
		}

		function onMouseMove(e) {
			const topscroll = window.pageYOffset;

			if (topscroll < 50) {
				mouse.x = e.clientX;
				mouse.y = e.clientY + window.pageYOffset;
			}
		}

		function onTouchMove(e) {
			if (e.touches.length > 0) {
				mouse.x = e.touches[0].clientX;
				mouse.y = e.touches[0].clientY;
			}
		}

		function onTouchEnd(e) {
			mouse.x = -9999;
			mouse.y = -9999;
		}

		function initScene() {
			ww = canvas.width = window.innerWidth;
			wh = canvas.height = window.innerHeight;

			//ctx.clearRect(0, 0, canvas.width, canvas.height);

			if (ww > 640) {
				ctx.font = "bold " + ww / 10 + "px sans-serif";
			}
			else{
				ctx.font = "bold " + ww / 8 + "px sans-serif";
			}

			ctx.textAlign = "center";
			ctx.fillText(copy.value, ww / 2, wh / 2);

			var data = ctx.getImageData(0, 0, ww, wh).data;
			//ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.globalCompositeOperation = "screen";

			particles = [];
			for (var i = 0; i < ww; i += Math.round(ww / 150)) {
				for (var j = 0; j < wh; j += Math.round(ww / 150)) {
					if (data[(i + j * ww) * 4 + 3] > 150) {
						particles.push(new Particle(i, j));
					}
				}
			}
			amount = particles.length;
		}

		function onMouseClick() {
			radius++;
			if (radius === 4) {
				radius = 1;
			}
		}

		function render(a) {
			requestAnimationFrame(render);
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			for (var i = 0; i < amount; i++) {
				particles[i].render();
			}
		}

		copy.addEventListener("keyup", initScene);
		//window.addEventListener("resize", initScene);
		window.addEventListener("mousemove", onMouseMove);
		window.addEventListener("touchmove", onTouchMove);
		window.addEventListener("click", onMouseClick);
		window.addEventListener("touchend", onTouchEnd);
		initScene();
		requestAnimationFrame(render);

		window.addEventListener("scroll", () => {
			const topscroll = window.pageYOffset;
			const totAltezza = window.innerHeight;

			if (topscroll > 20 && ww > 640) {
				mouse.x = window.innerWidth / 2;
				mouse.y = window.innerHeight / 2 - 30;
				radius = (topscroll * 20) / totAltezza;
			} else {
				mouse.x = 9999;
				mouse.y = 9999;
				radius = 2;
			}

			const name = document.getElementById("name");
			const home = document.getElementById("home");
			const faster = document.getElementById("wedoitfaster");
			const tt_srv = document.getElementById("tt_servizi");
			const gradient = document.getElementById("gradient");

			if (topscroll > 250) {
				faster.classList.add("animate__fadeOutDown");
			} else {
				faster.classList.remove("animate__fadeOutDown");
				faster.classList.remove("hidden");
			}

			if (topscroll < 100) {
				tt_srv.classList.add("hidden");
				setFadedown(false);
			}

			if (100 <= topscroll && topscroll < 400) {
				tt_srv.classList.remove("hidden");
				if (!fadedown) {
					tt_srv.classList.add("animate__fadeInDown");
					setFadedown(true);
				}
			}

			if (window.innerWidth > 640) {
				if (topscroll > 470) {
					name.style.opacity = "0%";
					home.style.opacity = "100%";
					gradient.classList.remove("hidden");
					gradient.classList.add("animate__fadeInUp");
					gradient.classList.remove("animate__fadeOutUp");
					faster.classList.add("hidden");
				} else {
					home.style.opacity = "0%";
					name.style.opacity = "100%";
					gradient.classList.add("animate__fadeOutUp");
				}
			} else {
				if (topscroll > 20) {
					name.style.opacity = "0%";
					home.style.opacity = "100%";
					
					faster.classList.add("hidden");
				} else {
					home.style.opacity = "0%";
					name.style.opacity = "100%";
				}
			}
		});
	}, [window.innerHeight]);

	return (
		<div className="overflow-scroll h-[200vh]">
			<div
				id="name"
				className="  fixed opacity-100 transition-all duration-700"
			>
				<canvas id="scene"></canvas>
				<input
					id="copy"
					type="text"
					value="Chimera Studios"
					className="hidden input_firstentry"
					onChange={() => {
						"";
					}}
				/>

				<div
					id="wedoitfaster"
					className="mx-auto w-full text-white z-10 md:bottom-80 bottom-56 tracking-widest animate__fadeInDown animate__animated animate__slow text-2xl md:text-3xl uppercase transition-all duration-[3000ms]"
				>
					<p id="" className="">
						We do it faster
					</p>
				</div>

				<span className="scroll-btn bottom-12">
					<a href="#/">
						<span className="mouse">
							<span></span>
						</span>
					</a>
				</span>
				<h6 className="text-white text-xl font-extralight hidden fixed bottom-40 left-[50%] translate-x-[-50%]">
					{t("scorri")}
				</h6>

				<div
					id="tt_servizi"
					className="text-white hidden text-center z-10 fixed top-0 flex justify-center items-center animate__animated  text-6xl font-bold w-full h-[100vh] uppercase transition-all duration-[3000ms]"
				>
					<h6>{t("i_nostri_servizi")}</h6>
				</div>
				<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
					<defs>
						<filter id="gooey">
							<feGaussianBlur
								in="SourceGraphic"
								stdDeviation="5"
								result="blur"
							/>
							<feColorMatrix
								in="blur"
								type="matrix"
								values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
								result="highContrastGraphic"
							/>
							<feComposite
								in="SourceGraphic"
								in2="highContrastGraphic"
								operator="atop"
							/>
						</filter>
					</defs>
				</svg>
			</div>
			<div id="home" className="opacity-0 transition-all duration-700">
				<Home />
			</div>
		</div>
	);
}

/*
<p
				id="wedoitfaster"
				className="text-white cursor-pointer border-white border-solid border-2 px-4 py-2 z-10 bottom-48 text-xl opacity-0 transition-all duration-[3000ms]"
				onClick={() => {window.location.href = "/home"}}
			>
				Entra
			</p>




			<button id="gooey-button" onClick={()=> window.location.href = '/home'}>
				Entra
				<span class="bubbles">
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
					<span class="bubble"></span>
				</span>
			</button>
*/
