import React from "react";

function TeamCard({ id, name, img, role,grade, className }) {
	return (
		<div
			key={id}
			id={"teamcard-" + id}
			draggable={false}
			className={`${className}  teamcard-div m-5`}
		>
			<div className="md:w-[350px] md:h-[437px] w-[200px] h-[200px] overflow-hidden  bg-transparent ">
				<img
					draggable={false}
					src={require(`../../../assets/${img}`)}
					alt={name}
					className=" transition-all duration-500 teamcard object-cover grayscale"
				/>
			</div>
			<p className="teamcard-text  w-full text-center uppercase pt-2">{name}</p>
			<p className="teamcard-descr  w-full text-center pt-2">
				{role}
			</p>
			<p className="teamcard-descr  w-full text-center pt-1">
				{grade}
			</p>
		</div>
	);
}

export default TeamCard;
