import axios from "axios";
import React, { useEffect, useState } from "react";
import SubmitButton from "../../components/atoms/SubmitButton/SubmitButton";

function Apply() {
    const [clicked, setClicked] = useState(false);
    const [canSubmit, setCanSubmit] = useState(0);
    const [canNext, setCanNext] = useState(0);
    const [first, setFirst] = useState(false);

    const [arte, setarte] = useState("");
    const [role, setRole] = useState("");
    const [words, setWords] = useState("");
    const [anni, setAnni] = useState("");
    const [link, setLink] = useState("");
    const [mail, setMail] = useState("");

    useEffect(() => {
        const a = arte.length > 0 ? 1 : 0;
        const r = role.length > 0 ? 1 : 0;
        const w = words.length > 0 ? 1 : 0;
        const y = anni.length > 0 ? 1 : 0;
        const l = link.length > 0 ? 1 : 0;
        const m = mail.length > 0 ? 1 : 0;
        setCanNext(a + r + w);
        setCanSubmit(y + l + m);
    }, [clicked, canSubmit, arte, role, words, anni, link]);

    var fired = false;

    window.onkeydown = function (e) {
        if (!fired && e.key === "Enter" && (canSubmit >= 3 || canNext >= 3)) {
            fired = true;

            document.getElementById("btn_submit").click();
        }
    };

    window.onkeyup = function (e) {
        fired = false;
    };

    const sendMail = async () => {
        const headers = {
            "Access-Control-Allow-Origin": "*",
        };

        const data = {
            name: arte,
            mail: mail,
            msg:
                "Ruolo: " +
                role +
                "\nDescrizione in 3 parole: " +
                words +
                "\nTra 2 anni... " +
                anni +
                "\nLink: " +
                link +
                "",
            page: "Apply to jobs with us",
        };

        axios
            .post("https://webinal.web.app/api/mail/chimera", data, { headers })
            .then((res) => {
                console.log(res.data);
                const link = String(res.data._id).toLowerCase();
                window.location.href = "aftermail/" + link.toLowerCase();
            });
    };

    return (
        <>
            <div className="w-[100vw] text-white h-[100vh] flex justify-center items-center">
                {!first ? (
                    <div className="w-full mx-auto p-4">
                        <div className=" grid grid-cols-2 gap-x-24 gap-y-8">
                            <div className=" col-span-2 pt-36 md:pt-0">
                                <div className="styled-input">
                                    <p className="md:text-6xl text-3xl uppercase text-center font-extralight">
                                        Apply for a job
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-2 flex items-end md:col-span-1 animate__bounceInDown animate__animated float-right">
                                <div className="styled-input ">
                                    <input
                                        type="text"
                                        value={arte}
                                        required
                                        onChange={(e) =>
                                            setarte(e.target.value)
                                        }
                                    />
                                    <label>Nome d'arte</label>
                                </div>
                            </div>
                            <div className="col-span-2 md:col-span-1 animate__bounceInRight animate__animated float-right">
                                <p className="md:text-2xl text-xl pl-[20px] font-extralight">
                                    Qual è il tuo ruolo ora come ora mondo della
                                    comunicazione?
                                </p>
                                <div className="styled-input">
                                    <input
                                        type="text"
                                        value={role}
                                        required
                                        onChange={(e) =>
                                            setRole(e.target.value)
                                        }
                                    />
                                    <label>Ruolo</label>
                                </div>
                            </div>
                            <div className="col-span-2 animate__bounceInLeft animate__animated float-right">
                                <div className="styled-input">
                                    <input
                                        type="text"
                                        value={words}
                                        required
                                        onChange={(e) =>
                                            setWords(e.target.value)
                                        }
                                    />
                                    <label>Descrivi in tre parole</label>
                                </div>
                            </div>
                        </div>
                        <div className="w-[100%] flex justify-end pt-12">
                            <SubmitButton
                                title={"Avanti"}
                                canSubmit={canNext}
                                nSubmit={3}
                                sendMail={() => setFirst(1)}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="w-full mx-auto p-4">
                        <div className=" grid grid-cols-2 gap-x-24 gap-y-8">
                            <div className=" col-span-2">
                                <div className="styled-input">
                                    <p className="text-4xl text-center font-light">
                                        Apply for a job
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-2 animate__bounceInRight animate__animated float-right">
                                <p className="text-2xl font-extralight">
                                    Se ti chiedessero cosa vorresti fare tra 2
                                    anni come risponderesti?
                                </p>

                                <div className="styled-input">
                                    <textarea
                                        type="text"
                                        value={anni}
                                        required
                                        onChange={(e) =>
                                            setAnni(e.target.value)
                                        }
                                    />
                                    <label>Come?</label>
                                </div>
                            </div>

                            <div className="col-span-2 animate__bounceInLeft animate__animated float-right">
                                <p>Facci vedere qualche tuo lavoro</p>
                                <div className="styled-input">
                                    <input
                                        type="text"
                                        value={link}
                                        required
                                        onChange={(e) =>
                                            setLink(e.target.value)
                                        }
                                    />
                                    <label>Link (Drive, Dropbox, ...)</label>
                                </div>
                            </div>
                            <div className="col-span-2 animate__bounceInLeft animate__animated float-right">
                                <div className="styled-input">
                                    <input
                                        type="text"
                                        value={mail}
                                        required
                                        onChange={(e) =>
                                            setMail(e.target.value)
                                        }
                                    />
                                    <label>Mail</label>
                                </div>
                            </div>
                        </div>
                        <div className="w-[100%] flex justify-end pt-12">
                            {!clicked && (
                                <SubmitButton
                                    title={"Invia"}
                                    canSubmit={canSubmit}
                                    nSubmit={3}
                                    sendMail={sendMail}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Apply;
