import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/molecules/Footer/Footer";

function Contatti() {
	const {t} = useTranslation();
	const elenco = [
		{
			title: "startaproject",
			url: "/start_a_project",
		},
		
		{
			title: "apply",
			url: "apply",
		},
	];

	const hovering = (ind) => {
		document.getElementsByClassName(ind)[0].classList.add("hover");
		document.getElementById(ind).style.bottom = "5px";
	};
	const notHovering = (ind) => {
		document.getElementsByClassName(ind)[0].classList.remove("hover");
		document.getElementById(ind).style.bottom = "0px";
	};

	return (
		<div className="text-white overflow-hidden">
			<video className="fixed w-full h-full object-cover opacity-50" autoPlay muted loop>
					<source src={require('../../assets/SFONDO(1).mov')} type="video/mp4" />
				</video>
			<div className="flex w-full h-[100vh] items-center left-4 md:left-32 relative">
				<div>
					<div className="inline-flex items-center animate__bounceInDown animate__animated">
						<button
							id="menu_btn"
							className={` w-12 h-12 mr-4 rounded-lg rotate-45 button-ellipse-grow effect-ellipse-grow`}
						></button>
						<p className="uppercase font-semibold p-5 text-5xl md:text-8xl">{t('contatti')}</p>
					</div>
					<p className=" text-4xl md:text-6xl pt-16 pb-8 font-light animate__animated animate__bounceInLeft ">
						{t('cosacerchi')}
					</p>
					{elenco.map((riga, index) => {
						return (
							<div key={index} className=" text-2xl md:text-4xl font-light">
								<div className="pt-5 inline-flex relative items-center animate__animated animate__bounceInLeft animate__fast animate__delay-1s ">
									<button
										id="menu_btn"
										onClick={() => window.location.href = riga.url}
										className={`${index} w-5 h-5 rounded-lg mr-4 rotate-45 button-ellipse-grow effect-ellipse-grow`}
                                        ></button>
									<p
										id={index}
										onMouseEnter={() => {
                                            hovering(index);
										}}
										onMouseLeave={() => {
                                            notHovering(index);
										}}
                                        
                                        onClick={() => window.location.href = riga.url}
										className="cursor-pointer pl-6 relative transition-all duration-300 bottom-0 "
									>
										{t(riga.title)}
									</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<Footer/>
		</div>
	);
}

export default Contatti;
