import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function CardServizi({ title, content, id, img1, img2 }) {
	const [open, setopen] = useState(false);
	const { t } = useTranslation();

	const h2 = "0" + id;
	const div = id + "div";
	const main = id + "main";
	const imm1 = id + "img1";

	const hovering = () => {
		if (!open) {
			//document.getElementById(h2).style.opacity = "100%";
			//document.getElementById('img1').style.opacity = "75%";
			document.getElementById(imm1).style.scale = "102%";
		}
	};
	const notHovering = () => {
		//document.getElementById(h2).style.opacity = "0%";
		document.getElementById(imm1).style.opacity = "100%";
		document.getElementById(imm1).style.scale = "100%";
	};

	const openPage = () => {
		/*setopen(true);

		const principal = document.getElementById(id);
		principal.removeAttribute("id");
		principal.setAttribute("id", "pr");

		const centrale = document.getElementById("central");
		const pr = document.getElementById("pr");
		const el = document.getElementById(main);
		const IDimg1 = document.getElementById(imm1);
		const IDimg2 = document.getElementById(imm2);

		IDimg1.style.display = "none";
		IDimg2.style.display = "block";

		centrale.style.left = "0%";
		centrale.style.width = "0vw";
		pr.style.top = "0%";
		pr.style.left = "0%";
		pr.style.scale = "100%";
		pr.style.opacity = "100%";
		//el.style.backgroundSize = "contain";
		el.style.zIndex = "50";
		el.style.position = "absolute";
		el.style.top = "50%";
		el.style.width = "100vw";
		el.style.left = "0%";
		el.setAttribute("className", "w-screen");

		*/
		document.getElementById(div).style.display = "none";
		window.location.href = `/servizio/${id}`;
		setTimeout(() => {
			window.location.href = `/servizio/${title}`;
		}, 0);
	};

	return (
		<div
			id={main}
			onClick={() => {
				openPage();
			}}
			onMouseEnter={() => hovering()}
			onMouseLeave={() => notHovering()}
			className={`md:w-[60vh] md:h-[60vh] w-[100vw] h-[100vw] card-servizi relative bg-cover cursor-pointer transition-all duration-1000`}
		>
			<img
				src={require(`../../../assets/${img1}`)}
				id={imm1}
				alt="foto"
				className=" transition-all duration-300 lblur-[2px] "
			></img>
			<p className="w-full text-shadow uppercase z-10 font-bold absolute top-[50%] translate-y-[-50%] text-center text-white text-3xl md:text-5xl">
				{t(title)}
			</p>

			<div
				id={div}
				className="w-full hidden absolute opacity-0 top-0 transition-all duration-300 ease-in-out z-40"
			></div>
			<h2
				id={h2}
				className="text-white opacity-0 relative text-center top-[-50%] transition-all duration-200 translate-y-[-50%] "
			>
				t{title}
			</h2>
		</div>
	);
}
