export const team = [
	{
		nome: "Giacomo Scardellato",
		foto: "team/jeck.png",
		grade: "",
		role: "Founder & CEO",
	},
	{
		nome: "Andrea Carlone",
		foto: "team/andreac.png",
		grade: "Collaboratore",
		role: "Videomaker / FPV Pilot",
	},
	{
		nome: "Andrea Brandi",
		foto: "team/andreabrandi.jpeg",
		grade: "Collaboratore",
		role: "Montatore Video",
	},
	{
		nome: "Lorenzo Fiale",
		foto: "team/lorenzofiale.jpeg",
		grade: "Collaboratore",
		role: "Regista",
	},
];
