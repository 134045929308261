import React, { FC, Suspense } from "react";
import FirstEntry from "./components/molecules/FirstEntry/FirstEntry";
import Home from "./components/molecules/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Servizio from "./Pages/Servizio/Servizio";
import Menu from "./components/molecules/Menu/Menu";
import Contatti from "./Pages/Contatti/Contatti";
import StartAProject from "./Pages/StartAProject/StartAProject";
import "./i18n";
import Apply from "./Pages/Apply/Apply";
import AfterMail from "./Pages/AfterMail/AfterMail";
import AboutUs from "./Pages/AboutUs/AboutUs";
import OurClients from "./Pages/OurClients/OurClients";

const App = () => {
	return (
		<div className="App">
			<Suspense fallback={null}>
				<BrowserRouter>
					<Menu />
					<Routes>
						<Route path={`/`} element={<FirstEntry />} />
						<Route path={`/services`} element={<Home />} />
						<Route path={`/clienti`} element={""} />
						<Route path={`/contatti`} element={<Contatti />} />
						<Route path={`/aboutus`} element={<AboutUs />} />
						<Route path={`/start_a_project`} element={<StartAProject />} />
						<Route path={`/apply`} element={<Apply />} />
						<Route path={"/ourclients"} element={<OurClients />} />
						<Route path={"servizio/:slug"} element={<Servizio />} />
						<Route path={"aftermail/:slug"} element={<AfterMail />} />
					</Routes>

				</BrowserRouter>
			</Suspense>
		</div>
	);
};


export default App;
