import React from "react";

function Footer() {
	return (
		<div className="relative w-[100vw] bg-white text-black md:h-52  border-solid border-white grid grid-cols-12">
			<div className="md:col-span-3 col-span-12  flex items-center justify-center">
				<p className="font-semibold p-4 text-xl">CHIMERA STUDIO</p>
			</div>
			<div className="md:col-start-6 md:col-span-3 col-start-2 col-span-12">
				<p className="uppercase cursor-pointer font-semibold pt-8">Contatti</p>
				<p>
					<a href="tel:+393517649360" className="pt-5 cursor-pointer">
						<b className="text-lg font-semibold">T </b>: +39 3517649360
					</a>
				</p>
				<p>
					<a
						href="mailto: info@chimerastudioss.com"
						className="pt-2 cursor-pointer"
					>
						<b className="text-lg font-semibold">E </b>:
						jeck@chimerastudioss.com
					</a>
				</p>
				<p>
					<a
						href="https://www.google.com/maps/place/Via+Antonello+da+Messina,+5,+20146+Milano+MI/@45.4665867,9.1396738,17z/data=!3m1!4b1!4m6!3m5!1s0x4786c103b52b7d4d:0x9b740345865a5b63!8m2!3d45.466583!4d9.1422487!16s%2Fg%2F11ss4pcdyr?entry=ttu"
						className="pt-2 cursor-pointer"
						target={'_blank'}
						rel="noreferrer"
					>
						<b className="text-lg font-semibold">A </b>: via Antonello da Messina 5, MI
					</a>
				</p>
			</div>
			<div className=" md:col-start-10 md:col-span-3 col-span-12 col-start-2">
				<p className="uppercase font-semibold pt-8">SiteMap</p>
				<p onClick={()=>window.location.href= '/'} className="pt-5 cursor-pointer">Home</p>
				<p onClick={()=>window.location.href= '/aboutus'} className="pt-1 cursor-pointer">Chi siamo</p>
				<p onClick={()=>window.location.href= '/contatti'} className="pt-1 cursor-pointer">Contattaci</p>
				<p
					onClick={() => (window.location.href = "/ourclients")}
					className="pt-1 cursor-pointer"
				>
					I Nostri Clienti
				</p>
			</div>
			<div className=" text-black cursor-pointer opacity-50 md:col-start-2 md:mt-0 md:col-span-2 col-span-12 mt-5 text-center">
				<a href="https://webinal.it" target="_blank" rel="noreferrer">
					2022 © Webinal
				</a>
			</div>
		</div>
	);
}

export default Footer;
