import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "animate.css";
import { Services } from "../../components/elements";
import { useTranslation } from "react-i18next";
import ElipseButton from "../../components/atoms/ElipseButton/ElipseButton";
import Footer from "../../components/molecules/Footer/Footer";

function Servizio() {
	const slug = useParams().slug;

	const main = "main" + slug;
	const [title, setTitle] = useState("");
	const [bg, setBg] = useState(null);
	const [testo, setTesto] = useState("");
	const [loaded, setLoaded] = useState(false);
	const [text, setText] = useState([]);
	const [prodotti, setProdotti] = useState([]);
	const [CTA, setCTA] = useState('')
	const { t } = useTranslation();
	let up = false;
	const [nextService, setNextService] = useState({});
	const [prevService, setPrevService] = useState({});

	const [scrollDir, setScrollDir] = useState("scrolling down");

	useEffect(() => {
		for (let i = 0; i < Services.length; i++) {
			if (slug === Services[i].title) {
				setTitle(t(Services[i].title));
				setBg(Services[i].img2);
				setTesto(t(Services[i].text));
				setCTA(Services[i].cta);
				setLoaded(true);

				if (Services[i].id < Services.length - 1) {
					setNextService(Services[i + 1]);
				} else {
					setNextService(false);
				}
				if (i > 0) {
					setPrevService(Services[i - 1]);
				} else {
					setPrevService(false);
				}
				console.log(nextService);

				if (Services[i].prodotti) {
					setProdotti(Services[i].prodotti);
				}
			}
		}
		setText(testo.split("."));
		console.log(testo.split("."));
		// scrollDir

		const threshold = 0;
		let lastScrollY = window.pageYOffset;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
				return;
			}
			const back = document.getElementById("link");
			setScrollDir(
				scrollY < lastScrollY
					? back.classList.remove("animate__fadeOutDown")
					: back.classList.add("animate__fadeOutDown")
			);
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			//console.log("topscroll", window.pageYOffset);
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		window.addEventListener("scroll", onScroll);
		//console.log(scrollDir);

		return () => window.removeEventListener("scroll", onScroll);
	}, [slug, scrollDir, nextService, t, testo]);

	const handleCarousel = (index, i) => {
		const immagini = document.getElementsByClassName(index + "img");
		const pic = document.getElementById(index + "img" + i);
		if (i > 0) {
			pic.style.display = "none";
		} else {
			for (let j = 0; j < immagini.length; j++) {
				immagini[j].style.display = "block";
			}
		}
	};

	const VideoProdotti = (prodotto, index) => {
		return (
			<div
				className={`col-span-12 flex justify-center ${
					prodotto.width < prodotto.height
						? "md:col-span-5"
						: "md:col-span-8 md:ml-10"
				} `}
			>
				{prodotto.type === "video" &&
					(window.innerWidth > 640 ? (
						<iframe
							title={prodotto.cliente}
							src={prodotto.url}
							frameborder="0"
							height={prodotto.width > prodotto.height ? "auto" : "100%"}
							width={prodotto.width < prodotto.height ? "100%" : "100%"}
						></iframe>
					) : (
						<iframe
							title={prodotto.cliente}
							src={prodotto.url}
							frameborder="0"
							height={prodotto.width > prodotto.height ? "auto" : "350px"}
							width={prodotto.width < prodotto.height ? "auto" : "100%"}
						></iframe>
					))}
				{prodotto.type === "foto" && (
					<div className="relative h-[350px] w-[450px]">
						{prodotto.url.map((url, i) => {
							return (
								<div
									id={index + "img" + i}
									key={i}
									className={`${index}img absolute h-full w-full bg-[rgb(10,10,10)] top-0`}
									style={{ userSelect: "none" }}
								>
									<img
										key={i}
										className={` h-[350px] max-w-[450px] object-cover `}
										src={require(`../../assets/${slug}/${url}`)}
										alt={prodotto.cliente}
									></img>
									<div
										type="button"
										onClick={() => handleCarousel(index, i)}
										className="w-12 h-12 text-5xl flex justify-center items-center absolute text-white bottom-2 right-2"
									>
										<i class="fa-solid fa-angles-right"></i>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	};

	const DescProdotti = (prodotto, index) => {
		const team = prodotto.team;
		const agenzia = prodotto.agenzia;
		const keys = Object.keys(team);

		return (
			<div
				className={`col-span-12 ${
					index % 2 !== 0 ? "text-left" : "text-right"
				}   ${
					prodotto.width < prodotto.height ? "md:col-span-7" : "md:col-span-4"
				}   text-white py-5 md:px-10 h-[400px] font-normal`}
			>
				<p className=" font-semibold text-3xl uppercase">{prodotto.cliente}</p>

				<div
					className={`${
						index % 2 !== 0 ? "text-left" : "text-right"
					} relative justify-center mt-8`}
				>
					{agenzia && (
						<p className=" mb-8 uppercase">
							Agenzia: <b className="capitalize">{agenzia}</b>
						</p>
					)}
					<p className={`${index % 2 !== 0 ? "text-left" : "text-right"} `}>
						Team:
					</p>
					{keys.map((p, i) => {
						return (
							<div className="">
								<div key={i} className=" text-lg">
									<p className="px-2 mt-2 uppercase">
										{t(p)}: <b className=" capitalize">{team[p]}</b>
									</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	return (
		<div className="bg-[rgb(10,10,10)] overflow-x-hidden">
			{loaded && (
				<div>
					<div>
						<div
							id={main}
							className={`w-[1oovw] md:h-auto h-[50vh] relative  bg-cover left-0 transition-all duration-1000`}
						>
							<div className="absolute bg-black w-full  opacity-50"></div>
							<div className="fixed  md:block hidden left-[45%] top-6  z-50">
								<a
									id="link"
									href="/services"
									className={`animate__animated ${
										up && "animate__fadeOutDown"
									} animate__fadeInUp whitespace-nowrap text-shadow fixed text-white font-light cursor-pointer text-lg`}
								>
									Back to Gallery
								</a>
							</div>

							<div className="w-full absolute top-[50%] translate-y-[-50%] z-10">
								<h1 className="animate__animated animate__fadeInRight z-10 md:top-[50%] w-[100vw] uppercase text-center text-shadow text-white font-bold text-2xl md:text-7xl">
									{title}
								</h1>
							</div>

							<img
								src={require(`../../assets/${bg}`)}
								alt="foto"
								className=" animate__animated animate__fadeIn w-full h-full object-cover brightness-50"
							></img>
						</div>

						<div className="text-white text-center text-4xl uppercase font-semibold pt-20">
							<p>{t(CTA)}</p>
						</div>
						<div className=" top-[70%] md:px-60 px-3 py-10 border-b-white border-b-2 border-solid mb-16 ">
							<div
								id={slug + "desc"}
								className=" animate__fadeInLeft animate__animated duration-500 text-center md:text-xl col-span-3 md:col-span-1 text-white"
							>
								{text.map((item, index) => {
									if (index < text.length - 1) {
										return (
											<div key={index}>
												<p className="pb-2">{item}.</p>
											</div>
										);
									}
								})}
							</div>
						</div>
					</div>

					{prodotti.length && (
						<div className=" md:px-52 ">
							{prodotti.map((prodotto, index) => {
								return index % 2 === 0 ? (
									<div
										key={index}
										className="grid grid-cols-12 md:mb-16 md:pb-16 mb-8 pb-0 border-b-white border-b-2 border-solid "
									>
										{VideoProdotti(prodotto, index)}
										{DescProdotti(prodotto, index)}
									</div>
								) : window.innerWidth > 640 ? (
									<div
										key={index}
										className="grid grid-cols-12 md:mb-16 md:pb-16 mb-8 pb-0 border-b-white border-b-2 border-solid "
									>
										{DescProdotti(prodotto, index)}
										{VideoProdotti(prodotto, index)}
									</div>
								) : (
									<div
										key={index}
										className="grid grid-cols-12 md:mb-16 md:pb-16 mb-8 pb-0 border-b-white border-b-2 border-solid"
									>
										{VideoProdotti(prodotto, index)}
										{DescProdotti(prodotto, index)}
									</div>
								);
							})}
						</div>
					)}
				</div>
			)}
			<div className="h-[150px] mt-24 relative w-[100vw]">
				<div className="absolute top-0 md:left-28 left-12">
					<ElipseButton
						visibility={prevService}
						title={t("prev_service")}
						url={prevService.title}
					/>
				</div>
				<div className="absolute top-0 md:right-0 right-12">
					<ElipseButton
						visibility={nextService}
						title={t("next_service")}
						url={nextService.title}
					/>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Servizio;

/*
<div className=" w-full h-[30vh] absolute  p-10 overflow-x-hidden">
				<img src={strisciatasecond} alt="" className="h-[80%] absolute striscia "/>
				<img src={strisciatasecond} alt="" className="h-[80%] absolute striscia " />
				<img src={strisciatasecond} alt="" className="h-[80%] absolute striscia " />
				
			</div>
			<div className=" w-full h-[30vh] absolute  p-10 translate-y-[100%] overflow-x-hidden">
				<img src={strisciatasecond} alt="" className="h-[80%] absolute striscia2 "/>
				<img src={strisciatasecond} alt="" className="h-[80%] absolute striscia2 " />
				<img src={strisciatasecond} alt="" className="h-[80%] absolute striscia2 " />
				
			</div>


			<video autoPlay muted loop className="w-full">
						<source
							src={require(`../../assets/${prodotto.url}`)}
							alt={prodotto.cliente}
						/>
					</video>
*/
