import React, { useState } from "react";
import X from "../../../assets/x-solid.svg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function Menu() {
	const [menu, setMenu] = useState(false);
	const { t } = useTranslation();
	
	
	const handleMenu = () => {
		const nav = document.getElementById("menu");
		const btn = document.getElementById("menu_btn");
		const esc = document.getElementById("menu_esc");
		const wrt = document.getElementById("menu_wrt");
		const txt = document.getElementsByClassName("menu_txt");
		
		if (!menu) {
			nav.style.zIndex = "900";
			nav.classList.remove("opacity-0");
			nav.classList.remove("hidden");
			nav.classList.add("opacity-100");
			esc.classList.remove("hidden");
			wrt.innerHTML = "Close";

			for (let i = 0; i < txt.length; i++) {
				txt[i].classList.add("animate__rollIn");
			}
			setTimeout(() => {
				for (let i = 0; i < txt.length; i++) {
					txt[i].classList.remove("animate__rollIn");
				}
			}, 1000);
		} else {
			nav.classList.remove("opacity-100");

			wrt.innerHTML = "Menu";
			for (let i = 0; i < txt.length; i++) {
				txt[i].classList.remove("animate__rollIn");
			}
			for (let i = 0; i < txt.length; i++) {
				txt[i].classList.add("animate__rollOut");
			}
			setTimeout(() => {
				nav.classList.add("opacity-0");
				nav.classList.add("hidden");
				esc.classList.add("hidden");
				nav.style.zIndex = "0";
			}, 700);
			setTimeout(() => {
				for (let i = 0; i < txt.length; i++) {
					txt[i].classList.remove("animate__rollOut");
				}
			}, 1000);
		}

		setMenu(!menu);
	};


	return (
		<div>
			<div onClick={()=>{window.location.href = '/'}} className="fixed top-2 left-8 z-50 w-[70px]">
				<img className="cursor-pointer" src={require('../../../assets/logo/LOGO.png')} alt="" />
			</div>
			<div className="text-shadow fixed flex right-4 top-2 z-[990] items-center">
				<p id="menu_wrt" className="text-white p-2 font-light text-lg">
					Menu
				</p>
				<button
					id="menu_btn"
					onClick={() => handleMenu()}
					className=" w-6 h-6 rotate-45 rounded-lg flex items-center justify-center button-ellipse-grow effect-ellipse-grow"
				>
					<img
						id="menu_esc"
						className="hidden rotate-[-45deg] p-[6px]"
						src={X}
						alt="exit"
					></img>
				</button>
				<div className="absolute right-28 top-2 text-white inline-flex z-50">
				<p
					className="px-2 cursor-pointer text-lg border-r-[1px] border-solid border-white"
					onClick={() => {
						i18next.changeLanguage("en");
					}}
				>
					en
				</p>
				<p
					className="px-2 cursor-pointer text-lg"
					onClick={() => {
						i18next.changeLanguage("it");
					}}
				>
					it
				</p>
			</div>
			</div>

			<div
				id="menu"
				className="fixed hidden flex items-center opacity-0 transition-all duration-300 bg-[rgb(10,10,10)] w-full h-[100vh]"
			>
				<div onClick={()=>{window.location.href = '/services'}} className="fixed top-2 left-8 z-50 w-[70px]">
				<img className="" src={require('../../../assets/logo/LOGO.png')} alt="" />
			</div>
				<div className="capitalize p-5 text-4xl md:text-8xl font-bold text-[#dddddd]">
					<p onClick={()=>window.location.href = '/services'} className=" py-2 menu_txt animate__animated cursor-pointer transition-all duration-300 hover:text-white hover:translate-x-10">
						{t("our_services")}
					</p>
					<p onClick={()=>window.location.href = '/ourclients'} className=" py-2 menu_txt animate__animated cursor-pointer transition-all duration-300 hover:text-white hover:translate-x-10">
						{t("our_clients")}
					</p>
					<p 
					onClick={()=>(window.location.href = "/aboutus")}
					className=" py-2 menu_txt animate__animated cursor-pointer transition-all duration-300 hover:text-white hover:translate-x-10">
						{t("who_are_we")}
					</p>
					<p
						onClick={() => (window.location.href = "/contatti")}
						className=" py-2 menu_txt animate__animated cursor-pointer transition-all duration-300 hover:text-white hover:translate-x-10"
					>
						{t("get_in_touch")}
					</p>
					
				</div>
			</div>
			
		</div>
	);
}

export default Menu;
