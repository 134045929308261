import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../components/atoms/SubmitButton/SubmitButton";
import Footer from "../../components/molecules/Footer/Footer";
import "./project.css";

function StartAProject() {
    const [clicked, setClicked] = useState(false);
    const [canSubmit, setCanSubmit] = useState(0);
    const { t } = useTranslation();

    const [nome, setNome] = useState("");
    const [mail, setMail] = useState("");
    const [text, setText] = useState("");

    useEffect(() => {
        
        const n = nome.length > 0 ? 1 : 0;
        const m = mail.length > 0 ? 1 : 0;
        const t = text.length > 0 ? 1 : 0;
        setCanSubmit(n + m + t);
    }, [clicked, canSubmit, nome, text, mail]);

    var fired = false;

    window.onkeydown = function (e) {
        if (!fired && e.key === "Enter" && canSubmit >= 3) {
            fired = true;
            sendMail();
        }
    };

    window.onkeyup = function (e) {
        fired = false;
    };

    const sendMail = async () => {
        const headers = {
            "Access-Control-Allow-Origin": "*",
        };

        const data = {
            name: nome,
            mail: mail,
            msg: text,
            page: "Start a Project",
        };

        axios
            .post("https://webinal.web.app/api/mail/chimera", data, {
                headers,
            })
            .then((res) => {
                console.log(res.data);
                const link = String(res.data._id).toLowerCase();
                window.location.href = "aftermail/" + link.toLowerCase();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="w-[100vw] text-white h-[100vh] flex justify-center items-center">
            <div className="w-full mx-auto p-4">
                <div className=" grid grid-cols-2 gap-x-24 gap-y-8">
                    <div className=" col-span-2 pt-36 md:pt-0">
                        <div className="styled-input">
                            <p className="md:text-6xl text-3xl uppercase text-center font-extralight">
                                {t("startaproject")}
                            </p>
                        </div>
                    </div>
                    <div className=" col-span-2 md:col-span-1 animate__bounceInDown animate__animated">
                        <div className="styled-input">
                            <input
                                type="text"
                                value={nome}
                                required
                                onChange={(e) => {
                                    setNome(e.target.value);
                                }}
                            />
                            <label>Nome</label>
                        </div>
                    </div>
                    <div className="col-span-2 md:col-span-1 animate__bounceInRight animate__animated float-right">
                        <div className="styled-input">
                            <input
                                type="text"
                                value={mail}
                                required
                                onChange={(e) => setMail(e.target.value)}
                            />
                            <label>Mail</label>
                        </div>
                    </div>
                    <div className=" col-span-2 animate__bounceInUp animate__animated">
                        <div className="styled-input">
                            <textarea
                                type="text"
                                value={text}
                                required
                                onChange={(e) => setText(e.target.value)}
                            />
                            <label>Parlaci del tuo progetto</label>
                        </div>
                    </div>
                </div>
                <input type="submit" className="hidden" />
                <div className="w-[100%] flex justify-end pt-12">
                    {!clicked && (
                        <SubmitButton
                            title={"Invia"}
                            canSubmit={canSubmit}
                            nSubmit={3}
                            sendMail={sendMail}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default StartAProject;

/*
<div className="col-xs-12">
				<div className="styled-input wide">
					<textarea required></textarea>
					<label>Message</label>
				</div>
			</div>
			<div className="col-xs-12">
				<div className="btn-lrg submit-btn">Send Message</div>
			</div>
*/
