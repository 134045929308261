import React from "react";

function ElipseButton({ title, url, visibility }) {
	return (
		<button
			onClick={() => (window.location.href = "/servizio/" + url)}
			className={`${!visibility && 'hidden'} md:translate-x-[-50%] button-ellipse-grow effect-ellipse-grow flex items-center justify-center rotate-45 w-24 h-24 border-2 border-solid border-white rounded-2xl`}
		>
			<p className=" text-xs rotate-[-45deg]">{title}</p>
		</button>
	);
}

export default ElipseButton;
