export const Services = [
	{
		// CAMPAGNE SOCIAL
		id: 0,
		title: "socialcontent",
		cta: '1_cta',
		text: "first_desc",
		img1: "cover/1.jpg",
		img2: "banner/1.jpg",
		prodotti: [
			{
				cliente: "Veuve Cliquot 2022",
				team: {
					produzione: "Chimera Studio",
					riprese: "Jeck Scardy",
					montaggio: "Jeck Scardy",
				},
				type: "video",
				url: "https://player.vimeo.com/video/762023959?h=97bb038c19",
				width: 640,
				height: 1137,
			},
			{
				cliente: "Twinset",
				agenzia: "Mb projects",
				team: {
					regia: "jeck scardy",
					operatore: "Andrea Carlone",
					montaggio: "Andrea Carlone",
					direzione: "Anna De Lo Russo",
					starring: "Ginevra Mavilla",
				},
				type: "video",
				url: "https://player.vimeo.com/video/762023930?h=e7681c0b62",
				width: 640,
				height: 1137,
			},
			{
				cliente: "emporio armani",
				agenzia: "Mb projects",
				team: {
					regia: "jeck scardy",
					operatore: "Lorenzo Fiale",
					montaggio: "Andrea Brandi",
					direzione: "Anna De Lo Russo",
				},
				type: "video",
				url: "https://player.vimeo.com/video/762023780?h=b969bf5b8d",
				width: 640,
				height: 360,
			},
			{
				cliente: "emporio armani",
				agenzia: "Mb projects",
				team: {
					regia: "jeck scardy",
					operatore: "Lorenzo Fiale",
					montaggio: "Andrea Brandi",
					direzione: "Anna De Lo Russo",
				},
				type: "video",
				url: "https://player.vimeo.com/video/762023812?h=06b16e4c24",
				width: 640,
				height: 1137,
			},
			{
				cliente: "elmar",
				team: { regia: "Jeck scardy", montaggio: "Jeck Scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/762023714?h=f90886da79",
				width: 640,
				height: 360,
			},
			{
				cliente: "caractere",
				agenzia: "Mb projects",
				team: {
					regia: "Jeck scardy",
					montaggio: "Andrea Carlone",
				},
				type: "video",
				url: "https://player.vimeo.com/video/762023507?h=d6b8b17f0c",
				width: 640,
				height: 360,
			},
			{
				cliente: "caractere",
				agenzia: "Mb projects",
				team: {
					regia: "Jeck scardy",
					montaggio: "Andrea Carlone",
				},
				type: "video",
				url: "https://player.vimeo.com/video/762023460?h=c0fdb6404c",
				width: 640,
				height: 800,
			},
			{
				cliente: "a-more",
				agenzia: "Chimera Studio",
				team: { regia: "jeck scardy", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/789455098?h=bf02f5bf39",
				width: 640,
				height: 1137,
			},
			{
				cliente: "cucinelli",
				agenzia: "Chimera Studio",
				team: { regia: "jeck scardy", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/789454386?h=bfa2ae2d2f",
				width: 640,
				height: 360,
			},
			
			
			
			{
				cliente: "twinset",
				agenzia: "ready2Fly",
				team: {
					regia: "Jeck Scardy",
					operatore: "Andrea Carlone",
					montaggio: "Andrea Carlone",
					direzione: "Anna De Lo Russo",
				},
				type: "video",
				url: "https://player.vimeo.com/video/789452317?h=c27fffdc4e",
				width: 640,
				height: 1137,
			},
			{
				cliente: "emporio armani",
				agenzia: "ready2Fly",
				team: {
					regia: "Jeck Scardy",
					operatore: "Lorenzo Fiale",
					montaggio: "Andrea Brandi",
					direzione: "Anna De Lo Russo",
				},
				type: "video",
				url: "https://player.vimeo.com/video/789451338?h=e71eea61bc",
				width: 640,
				height: 360,
			},
			{
				cliente: "coccinelle",
				agenzia: "TakeOff",
				team: {
					regia: "Jeck Scardy",
					operatore: "Andrea Carlone",
					montaggio: "Andrea Carlone",
				},
				type: "video",
				url: "https://player.vimeo.com/video/789450978?h=d1dda8ed20",
				width: 640,
				height: 1137,
			},
			{
				cliente: "coccinelle",
				agenzia: "TakeOff",
				team: {
					regia: "Jeck Scardy",
					operatore: "Andrea Carlone",
					montaggio: "Andrea Carlone",
				},
				type: "video",
				url: "https://player.vimeo.com/video/789450778?h=54bb5b971d",
				width: 640,
				height: 1137,
			},
			{
				cliente: "vera lab",
				agenzia: "Chimera Studio",
				team: {
					operatore: "Matthias D.G",
					montaggio: "Matthias D.G",
					"fpv pilot": "Andrea Carlone",
				},
				type: "video",
				url: "https://player.vimeo.com/video/780696192?h=164a32e1e2",
				width: 640,
				height: 360,
			},
		],
	},

	{
		//INSTANT CONTENT CREATION
		id: 1,
		title: "instantcontent",
		cta: '2_cta',
		text: "second_desc",
		img1: "cover/2.jpg",
		img2: "banner/2.jpg",
		prodotti: [{
			cliente: "polli",
			agenzia: "ready2Fly",
			team: { creator: "Elia S." },
			type: "video",
			url: "https://player.vimeo.com/video/789453793?h=5fb680190f",
			width: 640,
			height: 1137,
		},
		{
			cliente: "polli",
			agenzia: "ready2Fly",
			team: { creator: "Elia S." },
			type: "video",
			url: "https://player.vimeo.com/video/789453711?h=311bd5223f",
			width: 640,
			height: 1137,
		},
	
		{
			cliente: "kiton",
			agenzia: "ready2Fly",
			team: { regia: "Jeck Scardy", montaggio: "jeck scardy" },
			type: "video",
			url: "https://player.vimeo.com/video/789453589?h=97487d59eb",
			width: 640,
			height: 1137,
		},
		{
			cliente: "kiton",
			agenzia: "ready2Fly",
			team: { regia: "Jeck Scardy", montaggio: "jeck scardy" },
			type: "video",
			url: "https://player.vimeo.com/video/789453552?h=c5b6b0348b",
			width: 640,
			height: 1137,
		},],
	},
	{
		//COMMERCIAL
		id: 2,
		title: "commercial",
		cta: '3_cta',
		text: "third_desc",
		img1: "cover/3.jpg",
		img2: "banner/3.jpg",
		prodotti: [
			{
				cliente: "radio dj",
				agenzia: "Chimera Studio",
				team: {
					regia: "Lorenzo Fiale",
					doppiatore: "Matthias D.",
					operatore: "Samuel A.",
					montaggio: "Lorenzo Fiale - Matthias D.",
					runner: "Andrea Carlone",
					starring: "Greta Dovigi",
				},
				type: "video",
				url: "https://player.vimeo.com/video/789523908?h=991950ee94",
				width: 640,
				height: 360,
			},
			{
				cliente: "Diario girls chanel",
				agenzia: "Chimera Studio",
				team: {
					idea : "paolo stella",
					regia: "jeck scardy",
					montaggio: "jeck scardy",
				},
				type: "video",
				url: "https://player.vimeo.com/video/762023622?h=0f3050fbe8",
				width: 640,
				height: 360,
			},
			{
				cliente: "kiton",
				agenzia: "ready2Fly",
				team: { regia: "Jeck Scardy", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/789453589?h=97487d59eb",
				width: 640,
				height: 1137,
			},
			{
				cliente: "kiton",
				agenzia: "ready2Fly",
				team: { regia: "Jeck Scardy", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/789453552?h=c5b6b0348b",
				width: 640,
				height: 1137,
			},
		],
	},
	{
		//COPERTURA EVENTI
		id: 3,
		title: "photoevent",
		cta: '4_cta',
		text: "fourth_desc",
		img1: "cover/4.jpg",
		img2: "banner/4.jpg",
		prodotti: [
			{
				cliente: "kiton",
				agenzia: "ready2Fly",
				team: { regia: "Jeck Scardy", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/762023841?h=f6bb63cd03",
				width: 640,
				height: 360,
			},
			{
				cliente: "kiton",
				agenzia: "ready2Fly",
				team: { regia: "Jeck Scardy", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/789454665?h=c2d5241a36",
				width: 640,
				height: 360,
			},
			{
				cliente: "kiton",
				agenzia: "ready2Fly",
				team: { regia: "Jeck Scardy", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/789453914?h=1b9834eba7",
				width: 640,
				height: 360,
			},
			{
				cliente: "Antonia x valentino",
				agenzia: "Mb projects",
				team: { regia: "Jeck Scardy", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/789452813?h=937277ea42",
				width: 640,
				height: 1137,
			},
			{
				cliente: "H&M",
				team: ["Jeck", "Fede", "Alice"],
				type: "video",
				url: "https://player.vimeo.com/video/789451677?h=faa4001410",
				width: 640,
				height: 360,
			},
			{
				cliente: "Fondazione mente",
				agenzia: "Chimera studio",
				team: { operatore: "Lorenzo Fiale - Andrea Carlone", montaggio: "jeck scardy" },
				type: "video",
				url: "https://player.vimeo.com/video/789450195?h=b3febbc970",
				width: 640,
				height: 360,
			},
			{
				cliente: "Paolo Stella",
				team: ["Jeck", "Fede", "Alice"],
				type: "video",
				url: "https://player.vimeo.com/video/789449652?h=9db2d05168",
				width: 640,
				height: 360,
			},
		],
	},
	{
		//CONSULENZA INFLUENCER
		id: 4,
		title: "influencer",
		cta: '5_cta',
		text: "fifth_desc",
		img1: "cover/5.jpg",
		img2: "banner/5.jpg",
		prodotti: [],
	},
	{
		//3D
		id: 5,
		title: "3d",
		cta: '6_cta',
		text: "sixth_desc",
		img1: "cover/6.jpg",
		img2: "banner/6.jpg",
		prodotti: [
			{
				cliente: "A-more",
				agenzia: "Chimera studio",
				team: { '3D artist': "Michael Bellato" },
				type: "foto",
				url: ['001.jpg', '002.jpg', '003.jpg', '004.jpg', '005.jpg', '006.jpg', '007.jpg', '008.jpg', '009.jpg', '0010.jpg', '0011.jpg', '0012.jpg', '0013.jpg', '0014.jpg'],
				width: 640,
				height: 360,
			},
			
		],
	},
	{
		// DROVE FPV
		id: 6,
		title: "dronefpv",
		cta: '7_cta',
		text: "seventh_desc",
		img1: "cover/7.jpg",
		img2: "banner/7.jpg",
		prodotti: [
			{
				cliente: "2022 Fpv Drone Show Reel",
				team: {
					"fpv pilot": "Andrea Carlone",
				},
				type: "video",
				url: "https://player.vimeo.com/video/787824202?h=a5c1579387",
				width: 640,
				height: 360,
			},
		],
	},
	{
		//FOTO E-COMMERCE
		id: 7,
		title: "ecommerce",
		cta: '8_cta',
		text: "eigths_desc",
		img1: "cover/8.jpg",
		img2: "banner/8.jpg",
		prodotti: [],
	},
];
