import React, { useState } from "react";
import { useEffect } from "react";

function SubmitButton({ sendMail, title, canSubmit, nSubmit }) {
    const [opacita, setOpacita] = useState(0);

    useEffect(() => {
        setOpacita(Math.floor(1 + (canSubmit * 100) / nSubmit));
        document.getElementById("btn_submit").style.opacity = opacita + "%";
    }, [canSubmit, nSubmit, opacita]);

    const handleClick = () => {
        document.getElementById("btn_submit").style.opacity = "0%";
        sendMail();
        console.log("sendmail submit button");
    };

    return (
        <div>
            <button
                id="btn_submit"
                type="submit"
                className={` h-32 w-32 border-[3px] rounded-full border-solid border-white button-ellipse-grow effect-ellipse-grow`}
                onClick={() => {
                    canSubmit >= nSubmit && handleClick();
                }}
            >
                <p
                    className={`${
                        canSubmit === nSubmit ? "opacity-100" : "opacity-0"
                    }`}
                >
                    {title}
                </p>
            </button>
        </div>
    );
}

export default SubmitButton;
/*
className={`${canSubmit===0 ? 'opacity-0' : canSubmit ===1 ? 'opacity-30' : canSubmit === 2 ? 'opacity-60' : 'opacity-100'} h-32 w-32 border-[3px] rounded-full border-solid border-white button-ellipse-grow effect-ellipse-grow`}
*/
