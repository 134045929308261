import React, { useState, useEffect } from "react";

import CardServizi from "../../atoms/Card_servizi/CardServizi";
import { Services } from "../../elements";
import { useTranslation } from "react-i18next";

function Home() {
	//const [topscroll, setTopscroll] = useState(0);
	const [loaded, setloaded] = useState(false);
	const [menu, setMenu] = useState(false);
	const { t } = useTranslation();
	const ww = window.innerWidth;

	setTimeout(() => {
		if (!loaded) {
			const topscroll = 1;
			Services.map((servizio, i) => {
				return handleCarousel(topscroll, servizio.id, i + 1);
			});

			setloaded(true);
		}
	}, 1);

	window.addEventListener("load", () =>{
		setTimeout(()=>window.scrollY(2),300)
	})

	window.addEventListener("scroll", () => {
		handleScroll();

		const tt_srv = document.getElementById("tt_serviziH");
		const topscroll = window.pageYOffset;

		if ((topscroll > 570 && ww > 640) ||(topscroll > 200 && ww <= 640)) {
			tt_srv.classList.add("animate__fadeOutDown");
			setTimeout(() => {
				tt_srv.classList.add("hidden");
			}, 1000);
			document
				.getElementById("tt_serviziH")
				.classList.remove("animate__fadeInDown");
		} else {
			tt_srv.classList.remove("animate__fadeOutDown");
			tt_srv.classList.remove("hidden");
		}

		if ((topscroll > 5000 && ww > 640) || (topscroll > 6300 && ww < 640)) {
			document.getElementById("bottoni").style.opacity = "100%";
		} else {
			document.getElementById("bottoni").style.opacity = "0%";
		}
		if ((topscroll > 5000 && ww > 640) || (topscroll > 6800 && ww < 640)) {
			document.getElementById("bottoni").style.display = "grid";
		} else {
			document.getElementById("bottoni").style.display = "none";
		}
		if (topscroll > 1200 && ww < 640) {
			document.getElementById("scroll").style.display = "none";
		}
	});

	window.addEventListener("mousemove", () => {
		var e = window.event;
		var PosX = e.clientX;
		var PosY = e.clientY;

		const round = document.getElementById("bottone_round");

		const rect = GetScreenCordinates(round);
		

		if (
			PosY > rect.y &&
			PosY < rect.y + round.clientHeight &&
			PosX > rect.x &&
			PosX < rect.x + round.clientWidth
		) {
			const centerY = rect.y + round.clientHeight / 2;
			const centerX = rect.x + round.clientWidth / 2;
			//console.log(PosX);

			round.style.transform =
				"translate(" +
				(PosX - centerX) / 8 +
				"px, " +
				(PosY - centerY) / 8 +
				"px) rotate(45deg)";
		} else {
			round.style.transform = "translate(0px) rotate(45deg)";
		}
	});

	function GetScreenCordinates(obj) {
		var p = {};
		p.x = obj.offsetLeft;
		p.y = obj.offsetTop;
		p.x = obj.getBoundingClientRect().left;
		while (obj.offsetParent) {
			p.x = p.x + obj.offsetParent.offsetLeft;
			p.y = p.y + obj.offsetParent.offsetTop;
			p.x = obj.getBoundingClientRect().left;
			if (obj === document.getElementsByTagName("body")[0]) {
				break;
			} else {
				obj = obj.offsetParent;
			}
		}

		return p;
	}

	const handleScroll = () => {
		const topscroll = window.scrollY+1;

		Services.map((servizio, i) => {
			return handleCarousel(topscroll, servizio.id, i + 1);
		});
	};

	const handleCarousel = (topscroll, id, num) => {
		const delta = window.innerWidth > 720 ? num * 500 - 100 : num * 750 - 100;
		const opacity = Math.floor((topscroll - delta) / 3) + 40;
		const scale = Math.floor((topscroll - delta) / 10) + 50;
		let top = Math.floor((topscroll - delta) / 5) - 30;

		const left = Math.floor((topscroll - delta) / 15) + 50;

		const el = document.getElementById(id);
		
		if (ww > 720) {
			if (scale <= 90) {
				el.style.scale = scale + "%";
			}
			el.style.top = top + "%";
			el.style.opacity = opacity + "%";
		} else {
			el.style.scale = "100%";
			el.style.width = "100%";
			el.style.opacity = "100%";
			el.style.bottom = (top) + "%";
		}
		if (window.innerWidth > 720) {
			if (num % 2 === 0) {
				el.style.left = -left + "%";
			} else {
				el.style.left = left + "%";
			}
		} else {
			el.style.left = "-0%";
		}
	};

	const serviceHover = (title) => {
		const word = document.getElementById("service_hover");
		const titolo = title;
		word.innerHTML = t(title);
	};

	useEffect(() => {
		handleScroll();
		const porca = document.getElementsByClassName("verticaltext");
		for (let i = 0; i < porca.length; i++) {
			porca[i].style.writingMode = "vertical-lr";
			porca[i].style.textOrientation = "upright";
		}
	});

	return (
		<div
			id="corpo"
			className={`${
				ww > 640 ? "h-[850vh]" : "h-[980vh]"
			} w-full bg-[rgb(10,10,10)] overflow-scroll absolute p-0 m-0`}
		>
			<div className="text-white fixed bottom-10 left-[50%] translate-x-[-50%] z-50">
				<h2 id="service_hover" className="text-xl">
					{" "}
				</h2>
			</div>

			<div
				id="carosello"
				className=" bg-[rgb(10,10,10)] block  fixed h-[100vh] md:w-full transition-all duration-500"
			>
				<video className="fixed md:w-full h-full object-cover md:h-auto opacity-50" autoPlay muted loop>
					<source
						src={require("../../../assets/SFONDO.mov")}
						type="video/mp4"
					/>
				</video>

				<span id="scroll" className={`scroll-btn bottom-10`}>
					<a href="#/">
						<span className="mouse">
							<span></span>
						</span>
					</a>
				</span>

				<div
					id="gradient"
					className="animate__animated hidden w-full h-[18vh] from-[rgba(10,10,10,1)] bg-gradient-to-t bottom-0 fixed z-40"
					
				></div>

				<div
					id="tt_serviziH"
					className="text-white flex text-center z-10 fixed justify-center items-center animate__animated text-6xl font-bold w-full h-[100vh] uppercase transition-all duration-[3000ms]"
				>
					<h6>{t("i_nostri_servizi")}</h6>
				</div>
				<div
					id="central"
					className="h-96 border-pink-500 md:w-96 top-96 md:top-0 relative md:left-[50%] md:translate-x-[-50%]"
				>
					{Services.map((servizio, index) => {
						return (
							<div
								id={servizio.id}
								className={` carousel opacity-50 h-auto md:w-auto absolute md:translate-x-[-20%] `}
								onMouseEnter={() => serviceHover(servizio.title)}
								onMouseLeave={() => {
									serviceHover("");
								}}
								key={index}
							>
								<CardServizi
									title={servizio.title}
									id={servizio.id}
									img1={servizio.img1}
									img2={servizio.img2}
								/>
							</div>
						);
					})}
					<div
						id="bottoni"
						className="fixed h-[100vh] grid grid-cols-1 gap-0 w-full right-0 left-0 top-[20%] md:top-[75%] opacity-0 text-white transition-all md:duration-300"
					>
						<div className=" flex flex-col md:flex-row ">
						<button className=" md:translate-x-[-50%] my-10 md:my-0 button-ellipse-grow effect-ellipse-grow flex items-center justify-center rotate-45 w-32 h-32 border-2 border-solid border-white rounded-2xl">
							<a href="/ourclients" className=" rotate-[-45deg] text-xs">
								{t('our_clients')}
							</a>
						</button>
						<button
							id=""
							className=" md:translate-x-[50%] my-10 md:my-0 button-ellipse-grow effect-ellipse-grow flex items-center justify-center rotate-45 w-32 h-32 border-2 border-solid border-white rounded-2xl"
							onClick={() => (window.location.href = "/contatti")}
						>
							<a href="/contatti" className=" rotate-[-45deg]">
							{t('get_in_touch')}
							</a>
						</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
